import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Home from './components/Home/Home';
import NavBar from './components/NavBar/NavBar';
import Contact from "./components/Contact/Contact";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import variables from "./globalVariables";

function App() {
	const location = useLocation()

	const [displayLocation, setDisplayLocation] = useState(location)
	const [transitionStage, setTransitionStage] = useState('entering')

	useEffect(() => {
		if (location.pathname !== displayLocation.pathname) {
			setTransitionStage('exiting')
		} else {
			setDisplayLocation(location)
			window.scroll({ top: 0, left: 0, behavior: 'smooth' })
		}
	}, [displayLocation, location])

	const handleAnimationEnd = () => {
		if (transitionStage === 'exiting') {
			document.body.style.overflow = 'hidden'
			setDisplayLocation(location)
		}
		else {
			document.body.style.overflow = 'auto'
		}

		setTransitionStage(transitionStage === 'exiting' ? 'entering' : '')
	}

	return (
		<GoogleReCaptchaProvider reCaptchaKey={variables.reCAPTACHA_SITE_KEY} language="fr">
			<NavBar />
			<div className={transitionStage} onAnimationEnd={handleAnimationEnd} id="App">
				<Routes location={displayLocation}>
					<Route path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/contact' element={<Contact />} />
				</Routes>
			</div>
			<Footer />
		</GoogleReCaptchaProvider>
	);
}

export default App;
