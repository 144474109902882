import variables from "./globalVariables";

var loadedTexts: { [key: number]: string } = {};

async function fetchText(text_id: number, lang?: string, callback?: (text: string) => void): Promise<string> {
    if (lang === undefined) {
        lang = "fr";
    }

    if (loadedTexts[text_id]) {
        if (typeof callback === "function") {
            callback(loadedTexts[text_id])
        }
        return loadedTexts[text_id]
    } else {
        var responseText: string = "Error : no text found"
        await fetch(`${variables["server_url"]}text/${text_id}/${lang}`, { method: "POST" }).then(async (response) => {
            if (response.status === 200) {
                await response.text().then((text) => {
                    if (text) {
                        responseText = text;
                        loadedTexts[text_id] = responseText;
                    }
                })
            }
        })
        if (typeof callback === "function") {
            callback(responseText)
        }
        return responseText
    }
}

function clearTexts() {
    loadedTexts = {};
}

export { fetchText, clearTexts }