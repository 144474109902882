import "./SeeMore.scss"
import { FaAngleDoubleDown } from "react-icons/fa";
import Text from "../Text/Text";

export default function SeeMore() {
    const handleSeeMoreClick = () => {
        window.scrollBy({
            top: 200,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div className="see-more-container" onClick={handleSeeMoreClick}>
            <p><Text text_id={3} /></p>
            <FaAngleDoubleDown className="icon medium-icon" />
        </div>
    );
}