import { useEffect, useState } from "react";
import "./EllipseBG.scss"
import { isDesktop } from "react-device-detect"

export default function EllipseBG() {

    const [ellipseRGBColor, setEllipseRGBColor] = useState<Array<number>>([255, 255, 255]);
    const [mousePos, setMousePos] = useState<Array<number>>([0, 0]);

    useEffect(() => {

        // first ellipse follow mouse if isDesktop

        if (isDesktop) {
            const ellipse = document.getElementsByClassName("ellipse-bg")[0] as HTMLElement;

            const handleMouseMove = (e: MouseEvent | Event) => {
                if (typeof e === "object" && e instanceof MouseEvent) {
                    const x = e.clientX;
                    const y = e.clientY;
                    setMousePos([x, y]);
                    ellipse.style.setProperty("opacity", "1");
                }
                const x = mousePos[0];
                const y = mousePos[1];
                const width = window.innerWidth;
                const height = window.innerHeight;
                const xPercent = (x / width) * 100;
                const yPercent = (y / height) * 100 + window.scrollY / height * 100;
                ellipse.style.setProperty("cx", `${xPercent}%`);
                ellipse.style.setProperty("cy", `${yPercent}%`);

                // copy ellipseRGBColor
                const newEllipseRGBColor = [...ellipseRGBColor];
                // change color
                newEllipseRGBColor[0] = Math.floor((xPercent / 100) * 255);
                newEllipseRGBColor[1] = Math.floor((yPercent / 100) * 255);
                newEllipseRGBColor[2] = Math.floor((xPercent / 100) * 255);
                setEllipseRGBColor(newEllipseRGBColor);
                // change width and height (between 150 and 200)
                const newWidth = Math.floor((xPercent / 100) * 50 + 150);
                const newHeight = Math.floor((yPercent / 100) * 50 + 150);
                ellipse.style.setProperty("rx", `${newWidth}px`);
                ellipse.style.setProperty("ry", `${newHeight}px`);
            }

            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("scroll", handleMouseMove)

            return () => {
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("scroll", handleMouseMove)
            }
        }
    }, [mousePos, ellipseRGBColor]);
    return (
        <svg className="ellipse-background" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" id="bbblurry" opacity="1">
            <defs>
                <filter id="bbblurry-filter" x="-100%" y="-100%" width="400%" height="400%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feGaussianBlur stdDeviation="150" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" edgeMode="none" result="blur" />
                </filter>
            </defs>
            <g filter="url(#bbblurry-filter)">
                {isDesktop ?
                    <ellipse className="ellipse-bg ellipse-bg-follow-mouse" rx="200" ry="200" cx="-100%" cy="-100%" fill={`rgb(${ellipseRGBColor[0]}, ${ellipseRGBColor[1]}, ${ellipseRGBColor[2]})`}></ellipse>
                    :
                    <ellipse className="ellipse-bg ellipse-bg-respiration" rx="200" ry="200" cx="50%" cy="50%" fill="rgb(20, 150, 255)"></ellipse>}
            </g>
        </svg>
    )
}