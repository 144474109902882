import Text from "../Text/Text";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import variables from "../../globalVariables";
import "./Contact.scss";
import { useState } from "react";
import LandingDiv from "../LandingDiv/LandingDiv";

export default function Contact() {
    const [token, setToken] = useState<string | null>(null);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const [successText, setSuccessText] = useState<string | null>(null);
    const [sending, setSending] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setSending(true)
        event.preventDefault();
        setErrorText(null);
        setSuccessText(null);
        if (name !== "" && email !== "" && message !== "") {
            if (token !== null) {
                fetch(`${variables.server_url}contact`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        captchaToken: token,
                        name,
                        email,
                        message
                    })
                }).then(response => {
                    setSending(false)
                    setRefreshReCaptcha(r => !r);
                    if (response.status === 200) {
                        setSuccessText("Message envoyé avec succès");
                    } else {
                        setErrorText("Une erreur est survenue");
                    }
                });
            } else {
                setSending(false)
                setRefreshReCaptcha(r => !r);
                setErrorText("Veuillez valider le captcha");
            }
        } else {
            setSending(false)
            setRefreshReCaptcha(r => !r);
            setErrorText("Veuillez remplir tous les champs");
        }
    }
    return (
        <div id="Contact">
            <LandingDiv title_id={38} subtitle_id={39} />
            <div className="contrasted contact-section">
                <h2 className="title-font-size"><Text text_id={40} /></h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Nom</label>
                        <input onChange={handleNameChange} type="text" name="name" id="name" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input onChange={handleEmailChange} type="email" name="email" id="email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea onChange={handleMessageChange} name="message" id="message" rows={10} autoComplete="off" />
                    </div>
                    <GoogleReCaptcha onVerify={token => setToken(token)} refreshReCaptcha={refreshReCaptcha} />
                    <button disabled={sending}>Envoyer</button>
                    {errorText ? <p className="error-text">{errorText}</p> : ""}
                    {successText ? <p className="success-text">{successText}</p> : ""}
                </form>
            </div>
        </div>
    );
}
