import "./Argument.scss";

export default function Argument(props: { title: any, children: any }) {
    return (
        <div className="Argument">
            <h3 className="argument-title title-font-size">{props.title}</h3>
            <div className="argument-content">
                {props.children}
            </div>
        </div>
    );
}