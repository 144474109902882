import { useEffect, useState } from "react";
import { fetchText } from "../../fetchText";
import "./Text.scss";

export default function Text(props: { text_id: number, language?: string }) {
    const [textReady, setTextReady] = useState<boolean>(false)
    const [text, setText] = useState<string>('')

    useEffect(() => {
        fetchText(props.text_id, props.language)
            .then((text) => {
                setText(text)
                setTextReady(true)
            })
    }, [props.text_id, props.language])

    return (
        text && textReady ? <span className="text-component" dangerouslySetInnerHTML={{ __html: text }}></span> : <span className="text-skeleton"></span>
    )
}