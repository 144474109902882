import "./NavBar.scss";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function NavBar() {

    useEffect(() => {
        // hide navbar when scrolling down by setting the class "hide" to the navbar
        let prevScrollpos = window.pageYOffset;
        window.onscroll = function () {
            let currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                document.getElementById("NavBar")!.classList.remove("hide");
            } else {
                document.getElementById("NavBar")!.classList.add("hide");
            }
            prevScrollpos = currentScrollPos;
        }
    }, [])

    const handleLinesClick = () => {
        const openBurgerMenu = document.getElementById("open-burger-menu")!;
        const lines = openBurgerMenu.getElementsByClassName("burger-menu-line")!;
        openBurgerMenu.classList.toggle("show");
        for (let i = 0; i < lines.length; i++) {
            lines[i].classList.toggle("show");
        }
    }

    const handleNavClick = () => {
        const openBurgerMenu = document.getElementById("open-burger-menu")!;
        const lines = openBurgerMenu.getElementsByClassName("burger-menu-line")!;
        openBurgerMenu.classList.remove("show");
        for (let i = 0; i < lines.length; i++) {
            lines[i].classList.add("show");
        }
    }

    return (
        <>
            <div id="open-burger-menu">
                <div className="lines-container clickable" onClick={handleLinesClick}>
                    <div className="burger-menu-line top show"></div>
                    <div className="burger-menu-line middle show"></div>
                    <div className="burger-menu-line bottom show"></div>
                </div>
                <ul>
                    <li className="clickable">
                        <Link onClick={handleNavClick} to="/" className="title-font-size">Accueil</Link>
                    </li>
                    <li className="clickable">
                        <Link onClick={handleNavClick} to="/about" className="title-font-size">À propos</Link>
                    </li>
                    <li className="clickable">
                        <Link onClick={handleNavClick} to="/contact" className="title-font-size">Contact</Link>
                    </li>
                </ul>
            </div>
            <nav id="NavBar" className="hide">
                <ul>
                    <li className="clickable">
                        <Link onClick={handleNavClick} to="/" className="title-font-size">Accueil</Link>
                    </li>
                    <li className="clickable">
                        <Link onClick={handleNavClick} to="/about" className="title-font-size">À propos</Link>
                    </li>
                    <li className="clickable">
                        <Link onClick={handleNavClick} to="/contact" className="title-font-size">Contact</Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}